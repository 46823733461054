:root {
  --primary: #e96989;
  --secondary: #e96989;
  --old:#f8229f;
}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  width: 100vw;
  min-height: 100vh;
  overflow-x: hidden;
}

.show_whatsapp_icon {
  position: fixed;
  display: inline-grid;
  bottom: 5%;
  right: 1%;
  top: 80vh;
}

.show_whatsapp_icon img {
  width: 3rem;
  cursor: pointer;
  margin-bottom: .5rem;
}






p {
  margin-bottom: 0;
}

@media only screen and (max-width: 800px) {
  #hero .hero-container {
    display: block !important;
  }

  .hero-logo img {
    margin-bottom: 1rem;
    width: 30vw !important;
  }

  .show_whatsapp_icon img:last-child {
    visibility: visible;
  }

  .hero-container h4 {

    padding: .5rem !important;
  }

  .hero {
    /* height: 70vh !important; */
    height: 30vh !important;
  }
  .subDate_data p {
    font-size: 4vw !important;
}

  .show_whatsapp_icon {

    bottom: 10%;
    right: 6%;
    top: 85vh;
  }
  .mobileview{
    visibility: hidden;
  }

  .hero::after {
    height: 35vh !important;
  }
  .navbar{
    padding: .5rem 1rem !important;
  }

  .subheader {
    padding: .5rem !important;
  }

  .flate h1 {
    margin-bottom: 0 !important;
  }

  .hero .hero-container {
    display: block !important;
  }

  .hero-logo h4 {
    margin-bottom: 0 !important;
  }

  .hero-logo {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }



  .service_listing {
    padding: .5rem !important;
  }

  #slide_image {
    height: 25vh !important;
    
  }

  .ant-list-vertical .ant-list-item {
    flex-wrap: wrap;
  }

  .ant-drawer-body {
    padding: 0 !important;
  }

  #hero_image {
    height: 35vh !important;
  }

  .menu_cover img {
    width: 100%;
    height: 20vh;
    object-fit: cover;
  }

  .menu_cover {
    position: relative;
  }
.profileIcon img{
  width: 2rem;
  height: 2rem;
  border-radius: 100%;

}
  .menu_pro_icon {
    font-size: 6rem;
    color: var(--primary);
  }

  .profileIcon {
    position: absolute;
    bottom: -10%;
    right: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 6rem;
    height: 6rem;
    border-radius: 50%;
    /* background-color: #f8229f41; */
    border: 5px solid white;
  }

  .booking_list .ant-list .ant-list-item {
    display: block !important;
  }

  #book_img {

    aspect-ratio: 3 / 2 !important;
    object-fit: cover;
  }

  .service_Image {
    margin-top: 1rem !important;
  }

  .show_carousel {
    margin-top: 1rem;
  }

  .cart_setion {
    margin-top: 1rem;
  }

  #view_cart {
    width: 16rem !important;
  }

  .cart_setions {
    margin-top: 1rem;
  }

  .profile .ant-card {
    width: 100% !important;
  }
}

.log_head img {
  width: 30%;
}