.subheader{
    padding: .5rem 1.5rem;
    display: flex;
    justify-content: end;

}
.subheader_content img{
    width: 1.5rem;
    margin-left: 2rem;
    margin-right: .5rem;
}

.subheader_content{
    display: flex;
    
}
.subDate_data{
    display: flex;
    color:#000;
    margin-right: 20px;
}

.subDate_data > p > a{
    color:black;
}