@import url('https://fonts.googleapis.com/css2?family=Platypi:ital,wght@0,300..800;1,300..800&display=swap');

.navbar {
    width: 100%;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 999;
    transition: background-color 0.3s ease;
    padding: .5rem 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: whitesmoke;
}

#booking_list_title {
    font-weight: 600;
    text-align: center;
    font-size: 16px;
}

.mobile-card {
    width: 45vw;
}


.mobile-cat {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
}

#book_img_mob {
    width: 100%;
    border-radius: 8px;
    cursor: pointer;
    aspect-ratio: 2/2;
}

#booking_list_title_mob {
    font-weight: 600;
    text-align: center;
    font-size: .8em;
}

.navbar.sticky {
    position: fixed;
    top: 0;
    background: rgba(255, 255, 255, 0.25);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border: 1px solid rgba(255, 255, 255, 0.18);
    z-index: 2;
}

/* .navbar-content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: white;
  } */


/* #header{
    background-color: #eee;
    padding: 1rem 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
} */
.logo img {
    width: 6rem;
    cursor: pointer;
}

#book_btn button {
    background-color: var(--primary);
    color: white;
    border-style: none;
    padding: 0 3rem;
}

#book_btn button:hover {
    background-color: #d61c89 !important;

}


.log_btn button {
    background-color: var(--primary);
    color: white;
    border-radius: 25px;
    padding: .2rem 3rem;
    border-style: none;
}


.log_btn button:hover {
    background-color: #d61c89 !important;

}

.home_icon {
    color: var(--primary);
    font-size: 2rem;
    cursor: pointer;
    margin-right: 30px;
}

.cart-icon {
    color: var(--primary);
    font-size: 2rem;
    cursor: pointer;
    margin-right: 10px;
}

.icon_bottom_sheet {
    /* font-size: 2rem; */
    color: white;
}

.show_service_item {
    position: relative;
}

.cart_avtar button {
    background-color: var(--primary);
}

.cart_avtar button:hover {
    background-color: #d61c89 !important;

}


.cart_avtar {
    position: fixed;
    right: 8%;
    bottom: 5%;
    animation: cart 1s ease 0s 1 normal forwards;

}

@keyframes cart {
    0% {
        opacity: 0;
        transform: translateY(250px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.hero {
    width: 99%;
    height: 100%;
    background-size: cover;
}

.hero .slick-list {
    height: 70vh !important;
}

.hero_carousel {
    position: relative;

}
.marq-cotainer{
    display: flex;
    flex-direction: row;
}
.marquee-head {
    background-color: var(--primary);
    padding: 5px;
}

.marq-items {
    border: 1px solid;
    padding: 3px 30px;
    margin: 1px 5px;
    color: #000;
    display: flex;
    justify-content: center;
    border-radius: 20px;
    background-color: #fff;
    font-weight: 500;

}

@media (max-width: 750px) {
    .hero {
        width: 100%;
        height: 100px;
        background-size: cover;
    }

    .hero .slick-list {
        height: 25vh !important;
    }

    .hero_carousel {
        position: relative;

    }

    .marquee-head {
        background-color: var(--primary);
        padding: 5px;
        height: 200px;
    }

    .marq-items {
        padding: 2px 30px;
        margin: 1px 5px;
        color: #000;
        display: flex;
        justify-content: center;
        border-radius: 5px;
        background-color: #fff;
        font-weight: 500;

    }
}



.content-css {
    cursor: pointer;
    color: var(--primary);
}

.countdown_text {
    margin-top: 1rem;
    padding: 0 2rem;
}

.log_head {
    text-align: center;
}

.click_image {
    width: 2rem;
}

.login_title {
    text-align: center;
    margin-bottom: 1rem;
}

.active_pin_light {
    color: var(--primary);
}

.signup_user {
    text-align: center;
}

.signup_user p {
    margin-bottom: 1rem;
}

.hero_carousel::after {
    content: "";
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.411);
    position: absolute;
    top: 0;
    left: 0;

}

.ot_field {
    width: 100%;
    text-align: center;
}

.ot_field .ant-input {
    width: 100%;
    border-color: var(--primary);
    text-align: center;
}

.log_form {
    margin-top: 1rem;
}

.not_recived_otp {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.not_recived_otp p {
    margin-bottom: 0;
}

.verify_continue {
    text-align: center;
    margin-top: 1rem;

}

.duration_icon {
    margin-top: -.2rem;
    margin-right: .5rem;
    font-size: 1rem;
}

.verify_continue button {
    background-color: var(--primary);
    color: white;
    border-color: var(--primary);
}

.verify_continue button:hover {
    background-color: #d61c89 !important;

}


.log_continue {
    text-align: center;
}

.log_page button {
    background-color: var(--primary);
    color: white;
    border-style: none;
    padding: 0 3rem !important;
}

.log_page button:hover {
    background-color: #d61c89 !important;

}

.offer_apply {
    margin-top: 1rem;
}

.offer_apply .ant-form-item {
    margin-bottom: 0 !important;
}

.paybale_amt .ant-card {
    background-color: #f8229f2d;
}

.ant-alert {
    padding: 2px 6px !important;
}

.show_firework {
    width: 100%;
    z-index: 1;
    background-image: url("https://i.gifer.com/YKmD.gif") !important;
}

.coupon_fire {
    margin-top: .5rem;
}

.offer_apply button {
    background-color: var(--primary);
    color: white;
    border-style: none;
}

.offer_apply button:hover {
    background-color: #d61c89 !important;

}

.subDate_data p {
    font-size: 1vw;
}

#hero_image {
    width: 100%;
    height: 100%;
    aspect-ratio: 5/1.8;
    object-fit: cover;

}

.show_mobile_cat {
    height: 80vh;
    overflow-y: scroll;
}

@media (min-width: 1024px) {
    #hero {
        background-attachment: fixed;
    }
}

.slick-dots li.slick-active button {
    background-color: var(--primary) !important;
}

.details_summary .ant-card {
    margin-bottom: 1rem;
}

.close_icons {
    font-size: 1.2rem;
    color: red;
}

.hero .hero-container {
    position: absolute;
    top: 5%;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
    padding: 0 15px;
    z-index: 1;
}

@media (max-width: 991px) {
    #hero .hero-container {
        top: 50px;
    }
}

#hero h1 {
    color: #fff;
    font-family: "Raleway", sans-serif;
    font-size: 5rem;
    font-weight: 600;
    text-transform: uppercase;
    font-weight: 400;
}

.hero-container h4 {
    font-size: clamp(12px, 2vw, 20px);

    background-color: var(--primary);
    color: white;
    padding: 1rem;
}

.hero-content {
    animation: content 2s ease 0s 1 normal forwards;
}

@keyframes content {
    0% {
        animation-timing-function: ease-in;
        opacity: 0;
        transform: translateX(250px);
    }

    38% {
        animation-timing-function: ease-out;
        opacity: 1;
        transform: translateX(0);
    }

    55% {
        animation-timing-function: ease-in;
        transform: translateX(68px);
    }

    72% {
        animation-timing-function: ease-out;
        transform: translateX(0);
    }

    81% {
        animation-timing-function: ease-in;
        transform: translateX(32px);
    }

    90% {
        animation-timing-function: ease-out;
        transform: translateX(0);
    }

    95% {
        animation-timing-function: ease-in;
        transform: translateX(8px);
    }

    100% {
        animation-timing-function: ease-out;
        transform: translateX(0);
    }
}

.hero-content p:first-child {
    font-size: clamp(16px, 3vw, 24px);
    font-style: italic;
    margin-bottom: 1rem;
    color: white;

}

.hero-content p:last-child {
    font-size: clamp(12px, 3vw, 16px);
    font-style: italic;
    margin-top: 1rem;
    color: white;

}

.hero-logo h2 {
    font-size: clamp(12px, 3vw, 24px);

    color: white;
    background-color: var(--primary);
    display: inline;
    padding: 0 1rem;
}

.hero-content span {

    background-color: white;
    padding: .5rem;
    color: #f8229f;
    font-size: clamp(16px, 4vw, 32px);
    font-family: "Platypi", serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;

}

@media (max-width: 991px) {
    #hero h1 {
        font-size: 34px;
    }
}

#hero p {
    color: #ebebeb;
    font-weight: 700;
    font-size: 20px;
}

@media (max-width: 991px) {
    #hero p {
        font-size: 16px;
    }
}

.hero-logo {
    animation: log 2s ease 0s 1 normal forwards;
}

@keyframes log {
    0% {
        animation-timing-function: ease-in;
        opacity: 0;
        transform: translateX(-250px);
    }

    38% {
        animation-timing-function: ease-out;
        opacity: 1;
        transform: translateX(0);
    }

    55% {
        animation-timing-function: ease-in;
        transform: translateX(-68px);
    }

    72% {
        animation-timing-function: ease-out;
        transform: translateX(0);
    }

    81% {
        animation-timing-function: ease-in;
        transform: translateX(-28px);
    }

    90% {
        animation-timing-function: ease-out;
        transform: translateX(0);
    }

    95% {
        animation-timing-function: ease-in;
        transform: translateX(-8px);
    }

    100% {
        animation-timing-function: ease-out;
        transform: translateX(0);
    }
}

.hero-logo img {
    width: 20vw;
}

.hero-logo h1 {
    color: white;
    font-size: clamp(12px, 3vw, 20px);

}

/*--------------------------------------------------------------
# Buy Tickets Section
--------------------------------------------------------------*/
#buy-tickets {
    padding: 60px 0;

}

#buy-tickets .card {
    border: none;
    border-radius: 5px;
    transition: all 0.3s ease-in-out;
    box-shadow: 0 10px 25px 0 rgba(6, 12, 34, 0.1);
    /* background-color: red !important; */
}

#buy-tickets .card:hover {
    box-shadow: 0 10px 35px 0 rgba(6, 12, 34, 0.2);
    /* background-color:yellow !important; */
}

#buy-tickets .card hr {
    margin: 25px 0;
    /* background-color:yellow !important; */
}


#buy-tickets .card .card-title {
    margin: 10px 0;
    font-size: 14px;
    letter-spacing: 1px;
    font-weight: bold;
    background-color: pink !important;
}

#buy-tickets .card .card-price {
    font-size: 48px;
    margin: 0;
}

#buy-tickets .card ul li {
    margin-bottom: 20px;
}

#buy-tickets .card .text-muted {
    opacity: 0.7;
}

#buy-tickets .card .btn {
    font-size: 15px;
    border-radius: 50px;
    padding: 10px 40px;
    transition: all 0.2s;
    background-color: var(--primary);
    border: 0;
    color: #fff;
}

#buy-tickets .card .btn:hover {
    background-color: var(--primary);
}

#buy-tickets #buy-ticket-modal input,
#buy-tickets #buy-ticket-modal select {
    border-radius: 0;
}

#buy-tickets #buy-ticket-modal .btn {
    font-size: 15px;
    border-radius: 50px;
    padding: 10px 40px;
    transition: all 0.2s;
    background-color: var(--primary);
    border: 0;
    color: #fff;
}

#buy-tickets #buy-ticket-modal .btn:hover {
    background-color: var(--primary);
}

/* Sections Header
--------------------------------*/
.section-header {
    margin-bottom: 60px;
    position: relative;
    padding-bottom: 20px;
}

.section-header::before {
    content: "";
    position: absolute;
    display: block;
    width: 60px;
    height: 4px;
    background: var(--primary);
    bottom: 0;
    left: calc(50% - px);
}

.section-header h2 {
    font-size: clamp(16px, 4vw, 32px);
    text-transform: uppercase;
    text-align: left;
    font-weight: 700;
    margin-bottom: 10px;
}

.section-header p {
    text-align: left;
    padding: 0;
    margin: 0;
    font-size: 18px;
    font-weight: 500;
    color: #9195a2;
}

.section-with-bg {
    background-color: #f6f7fd;
}

/*--------------------------------------------------------------
# Schedule Section
--------------------------------------------------------------*/
#schedule {
    padding: 60px 0 60px 0;
}

#schedule .nav-tabs {
    text-align: center;
    margin: auto;
    display: block;
    border-bottom: 0;
    margin-bottom: 30px;
}

#schedule .nav-tabs li {
    display: inline-block;
    margin-bottom: 0;
}

#schedule .nav-tabs a {
    margin: .5rem;
    border: none;
    border-radius: 50px;
    font-weight: 600;
    background-color: #0e1b4d;
    color: #fff;
    padding: .5rem 1.5rem;
}

#buy-tickets .card:hover #book_img {
    transform: scale(1.1);
}



#book_img {
    transition: 0.3s ease-in-out;
}

#book_img {
    width: 100%;
    border-radius: 8px;
    cursor: pointer;
    aspect-ratio: 2/2;
}

@media (max-width: 991px) {
    #schedule .nav-tabs a {
        padding: 8px 60px;
    }
}

@media (max-width: 767px) {
    #schedule .nav-tabs a {
        padding: 8px 50px;
    }
}

@media (max-width: 480px) {
    #schedule .nav-tabs a {
        padding: 8px 30px;
    }
}

#schedule .nav-tabs a.active {
    background-color: var(--primary);
    color: #fff;
}

#schedule .sub-heading {
    text-align: center;
    font-size: 18px;
    font-style: italic;
    margin: 0 auto 30px auto;
}

@media (min-width: 991px) {
    #schedule .sub-heading {
        width: 75%;
    }
}

#schedule .tab-pane {
    transition: ease-in-out 0.2s;
}

#schedule .schedule-item {
    border-bottom: 1px solid #cad4f6;
    padding-top: 15px;
    padding-bottom: 15px;
    transition: background-color ease-in-out 0.3s;
}

#schedule .schedule-item:hover {
    background-color: #fff;
}

#schedule .schedule-item time {
    padding-bottom: 5px;
    display: inline-block;
}

#schedule .schedule-item .speaker {
    width: 60px;
    height: 60px;
    overflow: hidden;
    border-radius: 50%;
    float: left;
    margin: 0 10px 10px 0;
}

#schedule .schedule-item .speaker img {
    height: 100%;
    transform: translateX(-50%);
    margin-left: 50%;
    transition: all ease-in-out 0.3s;
}

#schedule .schedule-item h4 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 5px;
}

#schedule .schedule-item h4 span {
    font-style: italic;
    color: #19328e;
    font-weight: normal;
    font-size: 16px;
}

#schedule .schedule-item p {
    font-style: italic;
    color: #152b79;
    margin-bottom: 0;
}

.service_listing {
    padding: 1rem 3rem;
}

.video_section {
    width: 100%;
    /* Make the video fill its container horizontally */
    /* height: auto; Allow the height to adjust based on the video's aspect ratio */
    max-height: 40vh;
    object-fit: fill;
}

.cart_offer {
    margin-top: 1rem;
}

.carousel-video {
    border-radius: 10px;
}

#list_logo {
    width: 3rem;
}

.service_Image img {
    width: 10rem;
    height: 10rem;
    border-radius: 10px;
}

.service_Image {
    position: relative;
}

@media (max-width < 750){
  #prd-img{
    width: 30px;
  }
}

.book_service {
    position: absolute;
    bottom: -5%;
    left: 12%;
}

.cart_setion .ant-card-body {
    padding: .5rem;
}

.note li::before {
    content: "✔";
}

.note li {
    list-style: none;
}

.view_cart_btn {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#add_btn {
    padding: 0 3rem !important;
}

.book_service button {
    background: rgb(255, 255, 255);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border: 1px solid rgba(255, 255, 255, 0.18);
    color: var(--primary);
    font-weight: 600;
    border-color: var(--primary);
}

/* .book_service button:hover {
    background-color: #d61c89 !important;

} */


#avtar {
    width: 4rem;
    height: 4rem;
    padding: .2rem;
    border-radius: 100%;
    border: 2px solid var(--primary);
    object-fit: contain !important;
}

.cart_item img {
    width: 100%;

}

.empty_cart {
    text-align: center;

}

.cart_item p {
    color: gray;
}

.service_category {
    position: sticky;
    top: 5rem;
    left: 0;
}

@media (max-width: 800px) {
    .service_category {
        position: sticky;
        top: 5rem;
        width: 80%;
        margin-left: 10vw;
        /* height: 10vh; */
        left: 0;
    }

}

.sidebar_title {
    margin-top: 2rem;
    padding: 0 1rem;
}

.menu_items a {
    text-decoration: none;
    color: var(--primary) !important;
    font-weight: 600;

}



.pro_loc_icon {
    font-size: 1rem;
    margin-top: -.2rem;
}

.profile {
    width: 100%;
    margin: 1rem 0;
    display: flex;
    justify-content: center;
}

.profile .ant-card {
    width: 40%;
}

.logPage button {
    padding: 0 3rem !important;
}

.profile_img {
    display: flex;
    justify-content: center;
}

.model_Submit {
    text-align: end;
}

.model_Submit button {
    background-color: var(--primary);
    color: white;
    border-style: none;
    padding: 0 2rem;
}

.model_Submit button:hover {
    background-color: #d61c89 !important;

}


.ant-upload-wrapper .ant-upload-list {
    justify-content: center !important;
}

.image_hold {
    width: 6rem;
    height: 6rem;
    border-radius: 50%;
    padding: .5rem;
}

.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select {
    border-radius: 50% !important;
}

.pay_sum_item {
    margin-bottom: .5rem;
}

#View_details {
    color: var(--primary);
    font-weight: 600;
}

.sidebar_title p {
    font-weight: 600;
}

.call_icon {
    color: var(--primary);
    font-size: 1.2rem;
    margin-right: .5rem;
}

.sideSubMenus {
    font-size: 1.2rem;
    margin-top: -.2rem;
    margin-right: .5rem;
}

.ant-drawer-header-title {
    justify-content: end !important;
}

.cart_setion {
    position: sticky;
    top: 5rem;
    right: 0;
}

#view_cart {
    width: 20rem;
    background-color: var(--primary);
    color: white;
    border-style: none;
}

.view_cart {
    padding: 1rem 0;
}

.log_continue {
    text-align: center;
}

.log_continue button {
    background-color: var(--primary);
    color: white;
    border-style: none;
    padding: 0 3rem !important;
}

.log_continue button:hover {
    background-color: #d61c89 !important;

}

.log_details p:first-child {
    font-weight: 600;
}

.show_login button {
    background-color: transparent !important;
    border: 1px solid var(--primary) !important;
    color: var(--primary) !important;
}

.show_login button:hover {
    background-color: #d61c89 !important;

}


.my_order_book {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.loc_icon {
    font-size: 1.5rem;
}

.show_address button {
    background-color: var(--primary);
    color: white;
    border-style: none;
    margin: .5rem 0;
}

.show_address button:hover {
    background-color: #d61c89 !important;

}

.pay_item button {
    background-color: transparent !important;
    border: 1px solid var(--primary) !important;
    color: var(--primary) !important;
}

.pay_item button:hover {
    background-color: #d61c89 !important;

}

.order_image {
    width: 4rem;
    border-radius: 100%;
    aspect-ratio: 3/3;
}

.slote_item {
    margin-bottom: .5rem;
}

.slote_item button {
    background-color: transparent !important;
    border: 1px solid var(--primary) !important;
    color: var(--primary) !important;
}

.slote_item button:hover {
    background-color: #d61c89 !important;

}


.slote_listings {
    margin: 1rem;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;

    .ant-card {
        display: inline-block;
        width: 20%;
        margin: 0 .5rem;
        cursor: pointer;

    }
}


.show_action_btn button:first-child {
    background-color: transparent;
    border: 1px solid var(--primary);
    color: var(--primary);
}

.checkout_btn {
    text-align: center;
}

.date_delected {
    border-color: var(--primary);
    color: var(--primary);
    background-color: #d61c8828;
}

.time_delected {
    background-color: #f8229f23;
    border-color: var(--primary);
    color: #f8229f;
}

.show_loc_type {
    text-align: center;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(60px, 1fr));
    gap: 4px;
}

.show_loc_type .ant-card-body {
    padding: 10px !important;
}

.show_loc_type span {
    font-size: 1.5rem;
    color: var(--primary);
}

.add_address_btn button {
    background-color: var(--primary);
    color: white;
    border-style: none;
}

.add_address_btn button:hover {
    background-color: #d61c89 !important;

}

.homeSelected .ant-card {
    background-color: #f8229f1c;
    border: 1px solid var(--primary);
}

.pay_btn button {
    margin-top: 1rem;
    width: 100%;
    background-color: var(--primary);
    color: white;
    border-style: none;
}

.pay_btn button:hover {
    background-color: #d61c89 !important;

}


.order_placed img {
    width: 100%;
}

.order_placed p {
    color: var(--primary);
    text-transform: capitalize;
    margin: 1rem 0;
}

#slide_image {
    height: 70vh;
    object-fit: cover;
    border-radius: 10px;
}

.show_service_item {
    margin-top: 1rem;
}

.order_placed button {
    background-color: var(--primary);
    color: white;
    border-style: none;
}

.order_placed button:hover {
    background-color: #d61c89 !important;

}


/* .order_summary {
    position: sticky;
    top: 5rem;
    left: 0;
} */

.menu_icon {
    color: var(--primary);
    font-size: 2rem;
    cursor: pointer;
    margin-left: 20px;
}

.paybale_amt {
    margin-top: 1rem;
}

.pay_summer {
    margin-top: 1rem;
}

.pay_sum_item {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.pay_amt_item {
    font-size: 1.2rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 400;
}

.carat_item_list button {
    border-color: var(--primary);
}

.carat_item_list button:hover {
    background-color: #d61c89 !important;

}


.cart_setions {
    position: relative;
}

.checkout_btn button {
    background-color: var(--primary);
    color: white;
    border-style: none;
}

.checkout_btn button:hover {
    background-color: #d61c89 !important;

}

.slot_time_list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    /* Responsive grid with minimum item width of 200px */
    gap: 20px;
}

.slote_listings .ant-card-body {
    padding: 10px;
    text-align: center;
}

.slote_listings .ant-card p:last-child {
    font-weight: 600;
}

.slote_listings::-webkit-scrollbar {
    display: none !important;
    /* Hide the scrollbars */
}

/* discount coupon */

.code {
    border: 1px dashed var(--primary);
    padding: 0 .5rem;
    border-radius: 8px;

}

.coupon {
    border: 1px dashed lightgray;
    border-radius: 12px;
    box-shadow: 5px 8px 10px #d6d5d533;
    cursor: pointer;

}

.selectCoupon {
    border: 1px solid var(--primary);
    background-color: #f8229f42;


}

#discount_logo {
    width: 4rem;
}

.code:hover {
    background: green;
    color: #fff;
    cursor: pointer
}


/* ////////////////////////////////////book listing edited css ///////////////////////////////////////////// */

@media (min-width: 992px) {
    .row .col-lg-3 {
        flex: 0 0 auto;
        width: 21% !important;
    }
}

@media (max-width: 800px) {
    .row>* {
        padding: 0 7.5vw !important;
        /* background-color: red !important; */

    }
}




/* //////////////////////////////////////why choose us component css//////////////////////////////////////////////// */


#why-choose-us {
    padding: 60px 0;
    /* background-color: red; */
    background-color: #f6f7fd;
}

.why-choose-us-parent-container {
    background-color: #f6f7fd;
}



.design-container {
    display: flex;
    /* justify-content: space-evenly; */
    /* padding: 20px; */
    gap: 1vw;
    background-color: #f6f7fd;
    /* Adjust the background color as needed */
}

.design-item {
    /* background-color: #f00a0a; */
    border-radius: 10px;
    padding: 15px;
    text-align: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 30%;
    height: 60vh;
    /* Reduced width to make the cards smaller */
    margin: 10px;
    /* Added margin for better spacing between items */
}

.design-image {
    width: 100%;
    /* Adjusted image size to match smaller card size */
    height: 28vh;
    border-radius: 10px;
    margin-bottom: 10px;
    /* Added some space below the image */
}

.design-title {
    font-size: 1.1em;
    /* Slightly reduced font size */
    font-weight: bold;
    margin: 10px 0;
}

.design-text {
    font-size: 0.9em;
    /* Slightly reduced font size */
    color: #555;
}

@media (max-width: 800px) {
    .design-container {
        flex-direction: column;
        /* Stack the items vertically */
        align-items: center;
        /* Center the items */
    }

    .design-item {
        width: 90%;
        /* Make each item take most of the width on smaller screens */
        margin-bottom: 20px;
        /* Add some space between the items */
    }
}



/* //////////////////////////////faq css ////////////////////////////////////////////////////// */
.custom-panel-header {
    background-color: white !important;
    color: black !important;
    border-radius: 5px !important;
    border: none !important;
}

.custom-panel-header .ant-collapse-header {
    font-weight: bold;
}

.ant-collapse-content {
    background-color: white !important;
}

.ant-collapse-expand-icon {
    font-size: 18px;
    color: black !important;
}

.home-faq-section-item {
    padding: 60px 0;
    background-color: #f6f7fd !important;
    margin: 0 auto;
    /* Center the section horizontally */
}

@media (min-width: 801px) {
    .faq-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;
    }
}

@media (max-width: 800px) {
    .faq-grid {
        grid-template-columns: 1fr;
        /* Single column on mobile */
    }

    .home-faq-section-item {
        width: 100%;
        /* Full width on mobile */
    }
}




/* //////////////////////home footer page css //////////////////////////////////// */
/* Footer.css */
.app-footer {
    position: relative;
    background-image: url('../../public/images/nailfooter.png');
    color: white;
    width: 100%;
    height: 40vh;
    background-repeat: no-repeat;
    background-size: 100vw;
}


.image-cover {
    position: absolute;
    z-index: 2;
    top: 10px;
    right: 30px;
}

.img-footer-section {
    /* margin-bottom: 20px; */
    /* background-color: white; */
}

.second-img-footer-section {
    background-color: white;
    width: 40%;
    padding: 0 14px;
    padding-top: 14px;
    border-radius: 4px;

}

.footer-logo-cover {
    width: 150px;
    margin-bottom: 20px;
}

.footer-logo {
    width: 100px;
    margin-bottom: 20px;
}

..footer-section{
    margin-left: 50px;
}
.footer-section h3 {
    font-size: 18px;
    margin-bottom: 10px;
    color: #000;
    /* White text color */
}

.footer-section ul {
    list-style: none;
    padding: 0;
}

.footer-section ul li {
    margin-bottom: 10px;
}

.footer-section ul li a {
    color: #000;
    /* White text color */
    text-decoration: none;
}

.footer-section .social-links a {
    margin-right: 10px;
    display: inline-block;
    color: #000;
    /* White text color */
}

.footer-section .app-download a {
    display: block;
    margin-top: 10px;
    text-decoration: none;
    color: #000;
    /* White text color */
}

.footer-bottom {
    /* border-top: 1px solid #e8e8e8; */
    position: relative;
    margin-top: 200px;
    bottom: 0;
    padding-top: 20px;
    text-align: center;
    font-size: 14px;
    color: #000;
    /* White text color */
}


@media (max-width: 700px) {
    .app-footer {
        position: relative;
        background-image: url('../../public/images/nailfooter.png');
        color: white;
        width: 100%;
        height: 30vh;
        background-repeat: no-repeat;
        background-size: 100vw;
        bottom: -10px;
    }

    .footer-logo-cover {
        width: 100px;
        margin-bottom: 20px;
    }

    .footer-logo {
        width: 100px;
        margin-bottom: 20px;
    }

    .footer-bottom {
        /* border-top: 1px solid #e8e8e8; */
        position: absolute;
        text-align: center;
        font-size: 9px;
        width: 100%;
        height: 10px;
        color: #000;
        top: -35px;
        left:0px;
        /* White text color */
    }

    .social-links{
        position: absolute;
        bottom: 0px;
        top: 130px;
        left: 15px;
    }
}

/* ////////////////////////////////////////mobile navigation css ///////////////////////////////////////////////// */

.parent-navbar-conatine {
    background-color: white;
    height: 100vh;
    width: 100vw;


}

.cancel-modal{
    display: flex;
    flex-direction: column;
}






.navbar-conatiner {
    background-color: #e96989;
    /* border-radius:10px 10px 0 0px ; */
    /* padding: 0 calc(var(--nav-item-padding) * 2.4); */

    display: inline-block;
    /* box-sizing:content-box; */
    width: 100vw;
    margin-top: 0vh;
    /* border: 1px solid red; */
    position: fixed;
    height: 7vh;


    bottom: 0;
}

.list {
    display: flex;
    list-style: none;
    /* gap: 20vw; */
    justify-content: space-around;

}

.list ol,
ul {
    padding-left: 0rem !important;
}

.list .link {
    color: #e3dddd !important;
    text-decoration: none;

    display: flex;
    flex-direction: column;
    align-items: center;
    padding: var(--nav-item-padding);
    outline: none;
    background-color: transparent;

}

.list .text {
    font-size: 0.7em;
    opacity: 0;
    pointer-events: none;
    transition: 500ms ease-in-out;
    position: absolute;
    bottom: calc(.5 * var(--nav-item-padding));
    transform: translateY(50%);
    font-weight: 500;

}

.list .icon img {
    width: var(--icon-size);
    height: var(--icon-size);
    display: block;
    outline: none;
}

.list .icon {
    /* padding: 1em; */
    position: relative;
    z-index: 1;
}

.list .active .text {
    opacity: 1;
    pointer-events: all;
    transform: translateY(0);


}

:root {
    --icon-size: 1.8rem;
    --indicator-spacing: calc(var(--icon-size) / 8);
    --border-radius: calc(var(--icon-size) / 4);
    --nav-item-padding: calc(var(--icon-size) / 2);
    --background-color: #333;
}

.list .active .icon {
    transform: translateY(calc(-50% - 1rem));
}

.list .active .icon::after {
    content: "";
    position: absolute;
    z-index: -1;
    background-color: var(--primary);
    width: 3.2rem;
    height: 3.2rem;
    top: calc(-.1 * var(--icon-size));
    left: calc(0.1 * var(--icon-size));
    /* right: -28%;
  left: -55%;   */
    transform: translate(-28%, -18%);

    border-radius: 100%;


}

.list .active .icon::before {
    font-weight: 600;
    content: "";
    position: absolute;
    z-index: -1;
    background-color: white;
    width: 3.8rem;
    height: 3.8rem;
    top: -0.1rem;
    left: -0.2rem;
    /* right: -28%;
  left: -55%;   */
    transform: translate(-21%, -26%);

    border-radius: 100%;
    /* transform: translateY(-50%); */
}

.list .active {
    position: relative;
    outline: none;
    background-color: transparent !important;
}

.list .link:focus,
.list .link:active,
.list .link:visited,
.list li:focus,
.list li:active {
    background-color: transparent;
    outline: none;
    box-shadow: none;
    /* Ensure no box shadow */
}

/* ////////////////////////////////////bookservice carsol listing edited css ///////////////////////////////////////////// */
.service-carosel-booking-list-container {
    /* background-color: red; */
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding-top: 4px;
    overflow-x: auto;

}

.parent-card-container-of-service-carosel-booking-list-container {
    padding: 10px;
}

.sub-parent-card-container-of-service-carosel-booking-list-container {}

.card-body-service-carosel-booking-list-container {

    background-color: white;
    /* White background for the card */
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    padding: 10px;
    width: 11vw;
    /* Fixed width for the card */
}

/* #service-carosel-booking-list-container-book_img{
    width: 100%;
    height: auto;
    border-radius: 5px;
} */
.card-body-service-carosel-booking-list-container-title {
    margin-top: 10px;
    font-size: 0.7em;
    color: black;
    font-weight: 600;

}

@media (max-width: 800px) {
    .service-carosel-booking-list-container {
        /* background-color: red; */
        display: flex;
        flex-direction: row;
        /* justify-content: space-evenly;
         */
        gap: 2vw;
        padding-top: 4px;
        overflow-x: scroll;
        padding: 1vh 2vw;

    }

    .parent-card-container-of-service-carosel-booking-list-container {
        /* background-color: yellow !important;
        padding: 10px; */
    }

    .sub-parent-card-container-of-service-carosel-booking-list-container {}

    .card-body-service-carosel-booking-list-container {

        background-color: white;
        /* White background for the card */
        border-radius: 5px;
        overflow: hidden;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
        text-align: center;
        padding: 4px;
        width: 28vw;
        /* Fixed width for the card */
    }

    /* #service-carosel-booking-list-container-book_img{
        width: 100%;
        height: auto;
        border-radius: 5px;
    } */
    .card-body-service-carosel-booking-list-container-title {
        margin-top: 10px;
        font-size: 0.5em;
        color: black;
        font-weight: 600;

    }



}

.terms-and-conditions{
    padding: 100px;
}
.terms-and-conditions > p{
    font-size: 12px;
    font-weight: 500;
}

.terms-and-conditions > ul{
    margin-left: 20px;
}

.privacy-policy{
    padding: 100px;
}
.privacy-policy > p{
    font-size: 12px;
    font-weight: 500;
}

.privacy-policy > ul{
    margin-left: 20px;
}

.return-refund-policy{
    padding: 100px;
}
.return-refund-policy > p{
    font-size: 12px;
    font-weight: 500;
}

.return-refund-policy > ul{
    margin-left: 20px;
}

@media (max-width:750px){
    .terms-and-conditions{
        padding: 20px;
    }
    .terms-and-conditions > p{
        font-size: 12px;
        font-weight: 500;
    }

    .terms-and-conditions > ul{
        font-size: 12px;
        font-weight: 500;
        margin-left: 20px;
    }

    .privacy-policy{
        padding: 20px;
    }
    .privacy-policy > p{
        font-size: 12px;
        font-weight: 500;
    }

    .privacy-policy > ul{
        font-size: 12px;
        font-weight: 500;
        margin-left: 20px;
    }

    .return-refund-policy{
        padding: 20px;
    }
    .return-refund-policy > p{
        font-size: 12px;
        font-weight: 500;
    }

    .return-refund-policy > ul{
        font-size: 12px;
        font-weight: 500;
        margin-left: 20px;
    }
}

.terms-mobile{
    display: flex;
    flex-direction: column;
    margin-left: 25px;
    position: relative;
    
}

.booking_list{
    height: calc(100vh - 400px);
    overflow: auto;
}

.header-container{
    height: 70vh; 
    width: 100%;
    padding: 0;
    margin: 0;
}
.header-query-page {
    background-image: url("../assest/nail-art-5653459.jpg");
    background-repeat: no-repeat; 
    background-size: cover; 
    background-position: center; 
    height: 100vh; 
    width: 100%;
    padding: 20px;
}
.header-query-page-container{
  
    height: 90vh;
    display: flex;
    justify-content: center;
    flex-direction: row;
}


.header-query-page-subcontainer-1{
    width: 80%;
    /* background-color: #152b79; */
    padding: 20px;
    height: 50vh;
    display: flex;
    justify-content: center;
}
.header-query-page-subcontainer-2{
    width: 100%;
    /* background-color: #157922; */
    padding: 20px;
    height: 60vh;
    margin-top: 5vh;
    margin-left: 30vw;
}
.header-query-page-subcontainer-1-left{
    width: 80%;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
@media (max-width: 750px) {

    .enquiremob{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        width: 100%;
        top: 50px;
        right: 50px;
    }
    .header-container{
        height: 65vh; 
        width: 100%;
    }
    .header-query-page{
        height: 90vh; 
        width: 100%;
    }
    .header-query-page-container{
        flex-direction: column;
    }
    .header-query-page-subcontainer-1{
        width: 100%;
        height: 30vh;
        margin-top: 10vh;
        height: 20vh;
    }
    .header-query-page-subcontainer-1-left{
        width: 100%;
        /* margin-top: -5vh; */
        /* height: 20vh; */
    }
    .header-query-page-subcontainer-2 {
        width: 100%;
        margin-top: 5vh;
    }
}
/* EnquiryForm.css */
.enquiry-form-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* Enquiry Card Container */
  .enquiry-card {
    width: 400px;
    height: calc(60vh - 56px); /* Set a fixed height */
    overflow: hidden; /* Hide overflow outside the card */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  /* Scrollable content inside the card */
  .form-content {
    overflow-y: scroll; /* Enable vertical scrolling */
    padding:0px 2px;
    padding-bottom: 4px;
    margin-top: -14px;
    height: calc(65vh - 80px); /* Subtract header and footer height */
  
    /* Hide the scrollbar for modern browsers */
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
  }
  
  .form-content::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
  
  /* Ant Design Input Field Spacing */
  .ant-form-item {
    margin-bottom: 8px; /* Reduce the margin between form items */
  }
  
  .ant-btn {
    font-size: 16px;
    padding: 5px;
    height: 38px;
  }
  
  /* Media Query for screens smaller than 750px */
  @media (max-width: 750px) {
    .enquiry-card {
      width: 300px;
      height: 50vh; /* Increase height slightly for small screens */
      overflow: hidden;
    }
  
    .form-content {
      height: calc(50vh - 80px); /* Adjust height for small screens */
    }
  
    .ant-input,
    .ant-select-selector {
      font-size: 14px;
    }
  
    .ant-card-head-title {
      font-size: 16px;
    }
  
    .ant-btn {
      font-size: 14px;
      padding: 5px;
      height: 32px; /* Reduce button height for small screens */
    }
  }
  .header-query-page-subcontainer-2 .ant-btn {
    font-size: 16px;
    padding: 10px;
  }
  
  @media (max-width: 750px) {
    .header-query-page-subcontainer-2 .ant-btn {
      height: 40px;
    }
  }
  
  .know-more-contaner{
    padding: 60px 0;
    /* background-color: red; */
    background-color: #f6f7fd;
  }
.know-more-titel-text{
font-size: 0.9rem;
font-weight: 500;
}
/* Main container for the stats cards */
.stats-section {
    display: flex;
    justify-content: center;
    gap: 20px;
    padding: 20px;
  }
  
  /* Each card styling */
  .stats-card {
    background-color:var(--primary); /* Black background */
    color: #fff; /* White text */
    width: 200px;
    text-align: center;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  /* Icon container */
  .icon-container {
    width: 50px;
    height: 50px;
    margin: 0 auto 10px auto;
  }
  
  /* Icon styling */
  .icon {
    width: 100%; /* Make icon fill the container */
    height: auto;
  }
  
  /* Number styling */
  .number {
    font-size: 24px; /* Large font size */
    font-weight: bold;
    margin: 10px 0;
  }
  
  /* Label styling */
  .label {
    font-size: 16px;
    color: #ccc; /* Light gray text color */
  }
  
  /* Responsive styling for smaller screens */
  @media (max-width: 750px) {
    .stats-section {
      flex-direction: column; /* Stack the cards vertically */
      align-items: center;
    }
  
    .stats-card {
      width: 90%; /* Make cards occupy most of the screen width */
      margin: 10px 0; /* Add spacing between the stacked cards */
    }
  }
  
  .icon-box-container {
    height: 50px;
    width: 50px; /* Make width equal to height for a perfect circle */
    margin-right: 15px; /* Add some space between the icon and text */
    background-color: var(--primary); /* Lighter background to match icon styling */
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%; /* Make the container a circle */
    padding: 10px;
  }
  
  .icon-why-choose {
    width: 100%; /* Scale icon to fill 70% of the container */
    height: auto;
  }

  .why-choose-sub-container {
    width: 100%;
    display: flex;
    align-items: stretch; /* Ensure both items have equal height */
  }
  
  .why-choose-sub-container-item1,
  .why-choose-sub-container-item2 {
    padding: 20px; /* Add padding around the items */
    box-sizing: border-box; /* Include padding in height/width calculations */
  }
  
  .why-choose-sub-container-item1 {
    width: 60%;
  }
  
  .why-choose-sub-container-item2 {
    width: 40%;
    display: flex; /* To align content properly */
    justify-content: center; /* Center content horizontally */
    align-items: center; /* Center content vertically */
  }
  
  .why-choose-right-image {
    width: 100%; /* Make the image container full width */
    height: 70%; /* Ensure it matches the parent's height */
   
    background-size: cover; /* Make the image cover the entire container */
    background-repeat: no-repeat; /* Prevent image repetition */
    background-position: center; /* Center the image */
    border-radius: 70px; /* Optional: rounded corners for the image */
  }
  
  .design-box {
    display: flex;
    flex-direction: row;
    align-items: flex-start; /* Align the icon and text at the top */
    margin-bottom: 20px; /* Add spacing between boxes */
    /* padding: 10px; */
   
   
    /* width: 100%; Full width for larger screens */
    /* max-width: 600px; Optional max-width for a cleaner look */
  }
  
  .icon-box-text-container {
    display: flex;
    flex-direction: column;
    margin-left: 15px; /* Add margin to the left of the text */
  }
  
  .box-text {
    font-size: 0.9rem; /* Increase font size slightly for better readability */
    color: #555; /* Set a subtle text color */
  }
  
  h6 {
    font-weight: 600; /* Make headings bold */
    margin-bottom: 5px; /* Add spacing below the heading */
  }
  
  @media (max-width: 750px) {
    .why-choose-sub-container {
      flex-direction: column; /* Stack the items vertically */
    }
  
    .why-choose-sub-container-item1,
    .why-choose-sub-container-item2 {
      width: 100%; /* Make both items take full width */
      margin-bottom: 20px; /* Add spacing between the stacked items */
    }
  
    .why-choose-sub-container-item2 {
      height: auto; /* Let the height adjust automatically */
    }
  
    .why-choose-right-image {
      height: 200px; /* Set a fixed height for the image */
      background-size: contain; /* Ensure image fits without overflow */
      margin: 0 auto; /* Center the image container */
    }
  
  
    .design-box {
      flex-direction: column; /* Stack icon and text vertically on small screens */
      align-items: center; /* Center align the contents */
      text-align: center; /* Center the text */
      width: 100%; /* Make sure the boxes take up full width on smaller screens */
    }
  
    .icon-box-container {
      margin: 0 auto 10px auto; /* Center the icon container on small screens */
    }
  
    .icon-box-text-container {
      margin-left: 0; /* Remove left margin for small screens */
    }
  
    .box-text {
      font-size: 0.9rem; /* Slightly reduce font size for small screens */
    }
  }
  
  .testimonial-container {
    width: 100%;
    display: flex;
    overflow-x: auto; /* Enable horizontal scrolling */
    justify-content: flex-start; /* Align items to the start */
    scroll-snap-type: x mandatory; /* Enable snap scroll */
    scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  }
  


.testimonial-container::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
  
.testimonial-item {
    flex: 0 0 30%; /* Set fixed width as a percentage */
    margin-right: 20px; /* Space between items */
    height: 250px; /* Fixed height for all cards */
    border: 1px solid #ddd; /* Light border around each card */
    border-radius: 10px; /* Rounded corners */
    background-color: #fff; /* White background for the card */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for card effect */
    scroll-snap-align: start; /* Snap to the start of the item */
    padding: 20px;
  
    display: flex; /* Activate flexbox */
    flex-direction: column; /* Set the direction to column */
    justify-content: space-between; /* Push elements to top and bottom */
  }
  
  .testimonial-author {
    margin-top: 0; /* Remove the existing margin */
    display: flex;
    justify-content: space-between;
  }
  
  .testimonial-author-name{
    display: flex;
    flex-direction: column;
    
  }
  .author-name{
    font-weight: bold;
  }
  /* Responsive Design Adjustments */
@media (max-width: 768px) {
    .testimonial-item {
      flex: 0 0 45%; /* On medium screens, each item takes 45% of the viewport width */
      height: 220px; /* Adjust height for medium screens */
    }
  }
  
  @media (max-width: 480px) {
    .testimonial-container {
      padding: 0; /* Remove padding for small screens to maximize space */
    }
  
    .testimonial-item {
      flex: 0 0 90%; /* On small screens, each item takes 90% of the viewport width */
      margin-right: 10px; /* Reduce spacing between items */
      height: 200px; /* Adjust height for smaller screens */
    }
  }

  .contact-us-main-conatiner{
    width: 100%;
    padding: 20px;
    /* background-color: #ccc; */
    display: flex;
    justify-content: space-between;
  }
  .google-map-container{
    height: auto;
    width: 40vw;
    /* background-color: #0e1b4d; */
  }
  .enquary-form-container{
    height: auto;
    width: auto;
    /* background-color: rebeccapurple; */
    
  }
  @media (max-width: 750px) {
    .contact-us-main-conatiner{
        flex-direction: column;
       
    }
    .google-map-container{
        width: 100%;
        height: 30vh;
        margin-bottom: 3vh;
    }
  }